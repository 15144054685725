import GlowBottom from "../assets/images/glow-bottom.svg";
import {createEffect} from "solid-js";
import Logo from "../assets/olion-logo.svg";
import {setJoinWaitlistModalOpen} from "./modals/JoinWaitlistModal";
import {openCalendlyPopup} from "./modals/CalendlyPopup";

export default function Hero() {
    let canvasRef;
    createEffect(() => {
        const options = {
            quantity: canvasRef.dataset.particleQuantity,
            staticity: canvasRef.dataset.particleStaticity,
            ease: canvasRef.dataset.particleEase,
        };
        new ParticleAnimation(canvasRef, options);
         // AOS.init({
        //     once: true,
        //     disable: 'phone',
        //     duration: 1000,
        //     easing: 'ease-out-cubic',
        // })
    })

    return (
        <section id="home">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">

                {/*Particles animation*/}
                <div className="absolute inset-0 -z-10" aria-hidden="true">
                    <canvas ref={canvasRef}></canvas>
                </div>

                {/*Illustration*/}
                <div className="absolute inset-0 -z-10 -mx-28 rounded-b-[3rem] pointer-events-none overflow-hidden"
                     aria-hidden="true">
                    <div className="absolute left-1/2 -translate-x-1/2 bottom-0 -z-10">
                        <img src={GlowBottom} className="max-w-none" width="2146" height="774"
                             alt="Hero Illustration"/>
                    </div>
                </div>

                <div className="pt-32 pb-16 md:pt-52 md:pb-32">
                     {/* Hero content*/}
                    <div className="max-w-3xl mx-auto text-center">
                        <div className="flex justify-center">
                            <img src={Logo} className="w-48 h-48 md:w-56 md:h-56 mb-10" alt="Olion"/>
                        </div>
                        <div className="mb-6" data-aos="fade-down">
                            <div
                                className="inline-flex relative before:absolute before:inset-0 before:bg-gold-500 before:blur-md">
                                <a className="btn-sm py-0.5 text-white hover:text-white transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.gold.500),_theme(colors.gold.500))_padding-box,_linear-gradient(theme(colors.gold.500),_theme(colors.gold.200)_75%,_theme(colors.transparent)_100%)_border-box] relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                                   href="#0">
                                        <span className="relative inline-flex items-center">
                                            {/*A real Turnkey in the Jewelry Industry*/}
                                            all-in-one cash flow solution for jewelers
                                            {/*<span className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>*/}
                                        </span>
                                </a>
                            </div>
                        </div>
                        <h1 className="h1 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pb-4"
                            data-aos="fade-down">Meet Olion</h1>
                        <p className="text-lg text-slate-300 mb-8" data-aos="fade-down" data-aos-delay="200">
                            {/*World's first all-in-one cash flow solution for Jewelers*/}
                            A true turnkey AI-based platform for the Jewelry Industry
                        </p>
                        <div
                            className="max-w-xs mx-auto sm:max-w-none sm:inline-flex sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4"
                            data-aos="fade-down" data-aos-delay="400">
                            <div>
                                <a className="btn cursor-pointer text-slate-200 hover:text-white bg-slate-900 bg-opacity-25 hover:bg-opacity-30 w-full transition duration-150 ease-in-out"
                                   onClick={() => openCalendlyPopup()}>
                                    {/*<svg className="shrink-0 fill-slate-300 mr-3" xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*     width="16" height="16">*/}
                                    {/*    <path*/}
                                    {/*        d="m1.999 0 1 2-1 2 2-1 2 1-1-2 1-2-2 1zM11.999 0l1 2-1 2 2-1 2 1-1-2 1-2-2 1zM11.999 10l1 2-1 2 2-1 2 1-1-2 1-2-2 1zM6.292 7.586l2.646-2.647L11.06 7.06 8.413 9.707zM0 13.878l5.586-5.586 2.122 2.121L2.12 16z"/>*/}
                                    {/*</svg>*/}
                                    <span>Onboard Today</span>
                                    <span
                                        className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>
                                </a>
                            </div>
                            {/*<div>*/}
                            {/*    <a className="btn text-slate-900 bg-gradient-to-r from-white/80 via-white to-white/80 hover:bg-white w-full transition duration-150 ease-in-out group"*/}
                            {/*       href="#0">*/}
                            {/*        Schedule a demo <span*/}
                            {/*        className="tracking-normal text-gold-500 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                        <div className="text-center w-full font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pt-4"
                             data-aos="fade-down" data-aos-delay="400">
                            Join today and get exclusive deals!
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

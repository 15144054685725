import { Modal } from 'flowbite';
import {createEffect, createSignal} from "solid-js";
import Logo from "../../assets/olion-logo.svg";
import Illustration from "../../assets/images/auth-illustration.svg";
import {isProduction} from "../../App";
import {setScrollPopupOpen} from "./ScrollPopup";
import {waitlistNumber} from "../../pages/Home";

export const [joinWaitlistModalOpen, setJoinWaitlistModalOpen] = createSignal(false);

export default function JoinWaitlistModal() {
    let modalRef;
    let reCapchaRef;
    let errorRef;
    let submitButtonRef;
    const [modal, setModal] = createSignal(null);

    createEffect(() => {
        setModal(new Modal(modalRef));
        setTimeout(() => {
            grecaptcha.render(reCapchaRef, {
                'render': 'explicit',
                'sitekey' : '6LfzdvknAAAAALIa1nKjF4pzU8acYDZzx4Ok3jQa',
                'theme' : 'dark',
            });
        }, 1000);
    })

    createEffect(() => {
        if(modal()) joinWaitlistModalOpen() ? modal().show() : modal().hide();
        if(joinWaitlistModalOpen()) setScrollPopupOpen(false);
    })

    window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && joinWaitlistModalOpen()) {
            setJoinWaitlistModalOpen(false);
        }
    });

    const onSubmit = (e) => {
        e.preventDefault();

        const formData = Object.fromEntries(new FormData(e.target));

        if(!formData['g-recaptcha-response']) {
            alert('Please complete the reCaptcha')
            return;
        }
        //disable submit button
        submitButtonRef.classList.add('pointer-events-none');
        //reset error handler=
        errorRef.classList.add("hidden");
        errorRef.querySelector('#error-text').classList.remove("text-red-500");
        errorRef.querySelector('#error-text').classList.remove("text-green-500");
        errorRef.querySelector('#error-text').innerHTML = '';

        // fetch((isProduction ? '/portfolio' : '') + '/api/joinWaitlist', {
        fetch('/api/joinWaitlist', {
            method: 'POST',
            body:  JSON.stringify(Object.fromEntries(new FormData(e.target))),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                onSuccess();
            } else {
                onError(response);
            }
        }).catch((error) => {
            onError(error);
        });
    }

    const onSuccess = (e) => {
        try {
            window.lintrk('track', { conversion_id: 17528617 });
            gtag('event', 'waitlist_signup', {});
        } catch {}
        console.log('success');
        errorRef.classList.remove("hidden");
        errorRef.querySelector('#error-text').classList.remove("text-red-500");
        errorRef.querySelector('#error-text').classList.add("text-green-500");
        errorRef.querySelector('#error-text').innerHTML = 'Thank you for your interest in Olion. We will be in touch soon.';
        setTimeout(() => {
            setJoinWaitlistModalOpen(false);
        }, 3000);
    }

    const onError = (e) => {
        console.error(e);
        submitButtonRef.classList.add('pointer-events-auto');
        errorRef.classList.remove("hidden");
        errorRef.querySelector('#error-text').classList.remove("text-green-500");
        errorRef.querySelector('#error-text').classList.add("text-red-500");
        errorRef.querySelector('#error-text').innerHTML = `Something went wrong. Please try again later.`;
    }

    return (
        <div ref={modalRef} onClick={() => setJoinWaitlistModalOpen(false)} tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div class="relative w-full max-w-xl max-h-full" onClick={(e) => e.stopPropagation()}>
                {/* Modal content */}
                <div class="relative font-inter antialiased bg-slate-950 text-slate-200 tracking-tight border border-transparent rounded-2xl shadow-2xl [background:linear-gradient(theme(colors.slate.950),_theme(colors.slate.950))_padding-box,_conic-gradient(theme(colors.slate.400),_theme(colors.slate.700)_25%,_theme(colors.slate.700)_75%,_theme(colors.slate.400)_100%)_border-box] before:absolute before:inset-0 before:bg-slate-800/30 before:rounded-2xl">
                    <button onClick={() => setJoinWaitlistModalOpen(!joinWaitlistModalOpen())} type="button" class="z-10 absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>

                    <section className="relative p-4 sm:p-10">
                        {/* Illustration */}
                        <div className="md:block absolute left-1/2 -translate-x-1/2 -mt-36 blur-2xl opacity-70 pointer-events-none -z-10" aria-hidden="true">
                            <img src={Illustration} className="max-w-none" width="1440" height="450" alt="Page Illustration"/>
                        </div>

                        <div className="relative">
                            <div className="pt-4 pb-4 md:pt-6 md:pb-4">

                                {/* Page header */}
                                <div className="max-w-3xl mx-auto text-center pb-8">
                                    {/* Logo */}
                                    <div className="mb-5">
                                        <div className="inline-flex">
                                            <div className="relative flex items-center justify-center w-28 h-28">
                                                <img className="relative" src={Logo} alt="Olion"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Page title */}
                                    <h1 className="h2 bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60">Join our Waitlist</h1>
                                    <div className="inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-slate-200/60 via-slate-200 to-slate-200/60 pt-3">
                                        <b>{waitlistNumber()}</b>&nbsp;businesses have already signed up
                                    </div>
                                    <h5 className="h5 bg-clip-text text-transparent bg-gradient-to-r from-gold-500 to-gold-200 pt-4">
                                        Early adopters will get access to exclusive <b>deals</b> such as <b>free hosting</b> for the first three months
                                    </h5>
                                </div>

                                {/* Form */}
                                <div className="max-w-sm mx-auto">
                                    <form onError={onError} onSubmit={onSubmit}>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="company">Company <span className="text-rose-500">*</span></label>
                                                <input name="company" className="form-input w-full" type="text" placeholder="E.g., Olion Inc." required />
                                            </div>
                                            <div>
                                                <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="password">Website</label>
                                                <input name="website" className="form-input w-full" type="text" autoComplete="on" placeholder="E.g., https://example.com" />
                                            </div>
                                            <div>
                                                <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="full-name">Full Name <span className="text-rose-500">*</span></label>
                                                <input name="full_name" className="form-input w-full" type="text" placeholder="E.g., Mark Rossi" required />
                                            </div>
                                            <div>
                                                <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="email">Email <span className="text-rose-500">*</span></label>
                                                <input name="email" className="form-input w-full" type="email" placeholder="mark.rossi@company.com" required />
                                            </div>
                                            <div>
                                                <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="phone">Phone <span className="text-rose-500">*</span></label>
                                                <input name="phone" className="form-input w-full" type="tel" placeholder="+1 123 456 7890" required />
                                            </div>
                                            <div>
                                                <label className="block text-sm text-slate-300 font-medium mb-1" htmlFor="password">Request <span className="text-rose-500">*</span></label>
                                                <textarea name="request" className="form-input w-full min-h-[100px]" autoComplete="on" required placeholder="Tell us a bit about your business and why you would like to give Olion a try..." />
                                            </div>
                                        </div>
                                        <div ref={reCapchaRef} className="my-4 flex flex-row justify-center"></div>
                                        <div className="mt-6">
                                            <button ref={submitButtonRef} type="submit" className="btn text-sm text-white bg-gold-600 hover:bg-gold-700 w-full shadow-sm group">
                                                Submit
                                                {/*<span className="tracking-normal text-purple-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1">-&gt;</span>*/}
                                            </button>
                                        </div>
                                        <div className="mt-6">
                                            <p className="text-xs text-slate-300 text-center">
                                                By submitting this form, you agree to our {/*<a href="#" className="text-gold-600 hover:underline">Terms of Service</a> and*/} <a href="/privacy-policy" className="text-gold-600 hover:underline">Privacy Policy</a>.</p>
                                        </div>
                                        <div className="mt-6 hidden" ref={errorRef}>
                                            <p id="error-text" className="text-xs text-center text-red-500"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
